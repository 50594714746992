/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'box-arrow-down-right': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M8.636 12.5a.5.5 0 01-.5.5H1.5A1.5 1.5 0 010 11.5v-10A1.5 1.5 0 011.5 0h10A1.5 1.5 0 0113 1.5v6.636a.5.5 0 01-1 0V1.5a.5.5 0 00-.5-.5h-10a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h6.636a.5.5 0 01.5.5"/><path pid="1" fill-rule="evenodd" d="M16 15.5a.5.5 0 01-.5.5h-5a.5.5 0 010-1h3.793L6.146 6.854a.5.5 0 11.708-.708L15 14.293V10.5a.5.5 0 011 0z"/>',
    },
});
